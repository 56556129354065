body, section {
    padding-top: 56px;
}

.vote-splits-bar-chart {
    height: 60vh;
}

twitter-widget {
    margin-left: auto;
    margin-right: auto;
}
